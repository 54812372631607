import React from 'react'

import Layout from '../components/layout'
import Reveal from '../components/reveal'

export default ({ location }) => {
    return (
        <Layout location={location} title="Approach">
            <section className="section section-hero">
                <Reveal className="wrap">
                    <h1 className="label">Approach</h1>
                    <h2 className="title">
                        When we create, we collaborate. Asking questions,
                        listening curiously and working as a team helps us
                        deliver great product on time and on budget.
                    </h2>
                </Reveal>
            </section>
            <section className="section section-black">
                <div className="wrap">
                    <Reveal tag="h2" className="label">
                        TRIED-AND-TRUE PROCESS TO GUIDE OUR APPROACH
                    </Reveal>
                    <div className="approach">
                        <Reveal from="bottom" className="approach-item">
                            <h3>
                                01 <br /> Meet & brief
                            </h3>
                            <p>
                                We help you figure out what you need and make
                                sure we understand you and your business
                                perfectly.
                            </p>
                        </Reveal>
                        <Reveal
                            from="bottom"
                            delay="0.2"
                            className="approach-item"
                        >
                            <h3>
                                02 <br /> Research
                            </h3>
                            <p>
                                We dive deep into your brand and everything that
                                surrounds it to discover solutions that are
                                truly unlike usual.
                            </p>
                        </Reveal>
                        <Reveal from="bottom" className="approach-item">
                            <h3>
                                03 <br /> Concept design
                            </h3>
                            <p>
                                We sketch out ideas and check in with you to
                                make sure we’re on the right track.
                            </p>
                        </Reveal>
                        <Reveal
                            from="bottom"
                            delay="0.2"
                            className="approach-item"
                        >
                            <h3>
                                04 <br /> Shipping
                            </h3>
                            <p>
                                We finalise designs, code website, walk you
                                through content management system and do all the
                                things necessary for you to go live.
                            </p>
                        </Reveal>
                    </div>
                </div>
            </section>
        </Layout>
    )
}
